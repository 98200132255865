@use "./variables.scss" as *;

/* Modal */

.modal {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;

  &:before {
    background: $darkgreycolor;
    content: "";
    opacity: .93;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    position: fixed;
  }

  &.modal-open {
    display: flex;
  }

  &-btn-container {
    display: flex;
    justify-content: right;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999999;
  }

  &-content {
    max-width: 100%;
    position: relative;
    z-index: 99999;
    border-radius: 5px;
    padding-block: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include phone-only {
      height: 80vh;
    }
  }
}
