@use "./variables.scss" as *;

/* Map */

.map {
  border-top: $whitecolor 100px solid;
  position: relative;

  @include phone-only {
    border-inline: none;
    border-bottom: $whitecolor 20px solid;
    border-top: $whitecolor 45px solid;
  }

  .section-title {
    position: absolute;
    top: -50px;

    @include phone-only {
      position: static;
      padding-inline: 45px;
    }
  }

  .select-buttons:before {
    @include phone-only {
      content: "\f57d";
      font-weight: bold;
    }
  }

  button {
    position: absolute;

    &.all {
      top: 10px;
      right: 10px;
    }
  }

  &-img {
    max-width: 100%;
  }


  &-buttons {
    position: relative;
    padding: 10px;
    background-color: $bluecolor;
    transition: transform 0.3s, transform-origin 0.3s;

    @include phone-only {
      padding: 5px;
    }

    .active .hidden {
      display: flex;
      gap: 5px;
      letter-spacing: 2.5px;
      position: absolute;
      font-size: 12px;
      margin-left: 25px;
      background: $darkgreycolor;
      padding: 6px 8px;
      color: $whitecolor;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      width: max-content;
      font-family: $primaryfont;
      text-transform: uppercase;

      @include phone-only {
        font-size: 7px;
        padding: 3px 5px;
        margin-left: 15px;
        border-top-right-radius: 1.5px;
        border-bottom-right-radius: 1.5px;
        letter-spacing: 1.5px;
      }
    }

    .active .hidden:before {
      content: "";
      position: absolute;
      left: -12.5px;
      top: 0px;
      width: 0px;
      height: 0px;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-right: 13px solid $darkgreycolor;

      @include phone-only {
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 8px solid $darkgreycolor;
        left: -8px;
      }
    }

    button.active,
    button:focus {
      color: $darkgreycolor;
      box-shadow: none;
      opacity: 100%;
      border: none;
    }
  }
}

/* Map Zoom Buttons */

.zoom-controls {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @include phone-only {
    position: static;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    padding-inline: 40px;
  }


  button {
    position: static;
    font-size: 14px;
    display: flex;
    align-items: center;

    @include phone-only {
      min-width: auto;
      box-shadow: 0 0 9px #bbbbbb;
    }
  }

  .zoom-in-out {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  .zoom-directions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 5px 5px;
    grid-template-areas:
      ". up ."
      "left center right"
      ". down .";

    .left { grid-area: left; }
    .center { grid-area: center; }
    .up { grid-area: up; }
    .right { grid-area: right; }
    .down { grid-area: down; }
  }
}

/* Map Image Container */

.map-buttons-container {
  overflow: hidden;

  @include phone-only {
    height: 350px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: $bluecolor;
  }

  &.zoom-on {
    cursor: grab;
  }

  button {
    font-size: 16px;
    color: $accentcolor;
    background: none;
    padding: 0px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    border: none;

    @include phone-only {
      font-size: 6px;
    }

    &.gr {
      left: 54.5%;
      top: 27.3%;
    }

    &.ma {
      top: 32%;
      right: 51.8%;
    }

    &.es {
      top: 26%;
      right: 51%;
    }

    &.it {
      top: 22%;
      right: 47.5%;
    }

    &.va {
      top: 24.2%;
      right: 46.7%;
    }

    &.de {
      top: 18%;
      right: 47.5%;
    }

    &.gb-nir {
      top: 16%;
      right: 51.5%;
    }

    &.gb-eng {
      top: 17.8%;
      right: 50.3%;
    }

    &.ie {
      top: 18.5%;
      right: 52.4%;
      font-size: 14px;

      @include phone-only {
        font-size: 6px;
      }
    }

    &.gb-sct {
      top: 14.5%;
      right: 50.9%;
    }

    &.fr {
      top: 22%;
      right: 49.5%;
    }

    &.mt {
      top: 29%;
      right: 46.1%;
    }

    &.cy {
      top: 29.5%;
      right: 41.2%;
    }

    &.at {
      top: 20.7%;
      right: 46.8%;
    }

    &.cz {
      top: 19%;
      right: 46.2%;
    }

    &.be {
      top: 18.4%;
      right: 49.1%;
    }

    &.nl {
      top: 16.8%;
      right: 48.5%;
    }

    &.nz {
      bottom: 12.5%;
      right: 6%;
    }

    &.hu {
      top: 20.5%;
      right: 45%;
    }

    &.lv {
      top: 14.5%;
      right: 44.1%;
    }

    &.us {
      top: 27%;
      left: 23%;
    }

    &.pl {
      top: 17%;
      right: 45.4%;
    }

    &.bs {
      top: 37.4%;
      left: 27.7%;
    }

    &.cl {
      bottom: 24%;
      left: 30%;
    }

    &.ar {
      bottom: 18%;
      left: 32%;
    }

    &.bo {
      bottom: 30%;
      left: 31%;
    }

    &.co {
      bottom: 44%;
      left: 28%;
    }

    &.ec {
      bottom: 41%;
      left: 27%;
    }

    &.sg {
      bottom: 43.4%;
      right: 21.1%;
    }

    &.au {
      bottom: 23%;
      right: 13.5%;
    }
  }
}
