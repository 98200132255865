@use "./variables.scss" as *;

.timeline-buttons {
  flex-wrap: wrap;

  button {
    border: 4px solid $whitecolor;
    padding: 10px 19px;
    letter-spacing: 3px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $primaryfont;
    font-weight: 100;
    color: $greycolor; 
    background: $accentcolor;
    cursor: pointer;

    &.active {
      border: 4px solid $whitecolor;
    }
  }
}