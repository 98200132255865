@use "./variables.scss" as *;

.backtotop {
  position: fixed;
  bottom: 10px;
  right: 10px;

  a {
    display: block;
  }
}