@use "./variables.scss" as *;

/* Search */

.search { 
  &.section {
    border: $whitecolor 25px solid;
    flex-direction: row;
    justify-content: space-between;
    border-left-width: 45px;
    border-right-width: 45px;
  }

  &-input {
    position: relative;

    svg {
      position: absolute;
      color: $accentcolor;
      top: 0px;
      bottom: 0px;
      height: 100%;
      left: 14px;
      display: flex;
      align-items: center;
    }

    &-hidden-label {
      height: 0;
      visibility: hidden;
      padding: 0px 20px;
    }
  }

  input {
    font-family: $primaryfont;
    background: $greycolor;
    padding: 14px 20px 14px 40px;
    font-size: 11px;
    border: none;
    box-shadow: 0 0 9px #bbbbbb;
    border: 4px solid $whitecolor;
    letter-spacing: 1.5px;
    display: inline;
    width: 100%;
    text-transform: uppercase;
  }
}