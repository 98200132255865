@use "./variables.scss" as *;

/* Slider */

.slider {
  display: flex;
  max-width: 100%;
  padding-block: 20px;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  position: relative;

  &-container {
    position: relative;
    max-width: 80vw;
    max-height: 90vh;
    aspect-ratio: 1 / 1;

    @include phone-only { 
      max-height: 70vh;
    }
  }

  &-btn {
    z-index: 9;
  }

  &-content {
    border-radius: 5px;
    width: 100%;
    height: 100%;

    &.-text {
      background: $accentcolor;
      color: $whitecolor;
      padding: 7% 7% 60px;
      @include flex-center;
      line-height: 2.0;

      p {
        font-size: 40px;
        font-family: $secondaryfont;

        @include phone-only {
          font-size: 20px;
        }
      }
    }
  }

  &-title {
    position: absolute;
    bottom: 25px;
    text-align: center;
    color: $whitecolor;
    font-size: 14px;
    font-weight: bold;
    font-family: $secondaryfont;
    background: grey;
    padding: 7px 12px;
    border-radius: 5px;
    letter-spacing: 2px;
  }

  &-btn {
    @include phone-only {
      position: absolute;
      bottom: -90px;
      height: 70px;
    }

    &.-next {
      @include phone-only {
        right: 0px;
      }
    }

    &.-previous {
      @include phone-only {
        left: 0px;
      }
    }  
  }

  .slide {
    display: none;
    animation: slide-in 1s;
    width: 100%;
    height: 100%;

    &.show {
      display: flex;
      justify-content: center;
    }
  }

  img {
    max-width: 100%;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(10%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}