@use "./variables.scss" as *;

/* CSS reset & element styles */

html, body, p, h1, h2, h3 {
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  font-weight: 300;
}

html {
	scroll-behavior: smooth;
}

ul {
  margin: 0;
  list-style-type: none;
  padding-left: 0px;
  text-align: left;
}

button {
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  background-color: $bluecolor;
  font-family: $primaryfont;
  letter-spacing: 1.0px;
  background-image: url("../../../public/images/world-green.svg");
  background-position: top center;
  background-attachment: fixed;
}
