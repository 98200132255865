@use "./variables.scss" as *;

/* Main Layout */

main, .banner, .header-container {
  margin: 0 auto;
  max-width: 1150px;

  @include tablet-up-to-desktop {
    padding-inline: 20px;
  }
}

.compflex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2%;

  .section {
      margin-bottom: 0px;
  }

  .year {
    flex-basis: 38%;

    @include phone-only {
      flex-basis: 90%;
    }
  }

  .number {
    flex-basis: 60%;

    @include phone-only {
      flex-basis: 90%;
      order: 3;
    }
  }
}

/* Section Layout */

.section {
  @include flex-center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  background: $whitecolor;
  @include whiteborder;
  margin: 20px auto;

  @include phone-only {
    width: 90%;
  }

  &.beside {
    flex-direction: row;
    justify-content: space-between;
  }

  &-title {
    width: 100%;
    @include flex-center;
    letter-spacing: 5px;
    font-size: 16px;
    line-height: 2.0;
    text-transform: uppercase;
  }

  &-container {
    @include flex-center;
    gap: 15px;
  }
}

/* Banner Layout */ 

.banner {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  padding-top: 150px;
  flex-wrap: wrap; 

  @include phone-only {
    flex-direction: column;
  }

  > div {
    @include flex-center;
    text-align: center;
  }
}

/* Hide and Show */

body {
  .hidden { 
    display: none; 
  }

  .desktop-only {
    display: flex;

    @include phone-only {
      display: none;
    }
  }

  .mobile-only {
    display: none;

    @include phone-only {
      display: flex;
    }
  }
}