@use "./variables.scss" as *;

/* Comingsoon */

.coming-soon {
  ul li { 
    font-size: 18px;
    line-height: 3.0;
  }

  .post-it-note h2 { 
    font-size: 25px;

    @include phone-only {
      font-size: 20px;
    }
  }
}
