@use "./variables.scss" as *;

/* Header and Footer */

header, footer {
  @include flex-center;
  padding: 30px;
  background: $accentcolor;
  flex-direction: column;
  gap: 12px;

  @include phone-only {
    padding: 20px;
  }
}

header {
  position: fixed;
  z-index: 9;
  width: 100vw;
}

footer {
  font-size: 12px;
  color: $greycolor;
  margin-top: 150px;
}

/* Fixed Header */

.header {
  &.fixed {
    position: fixed;
    z-index: 99999;
    justify-content: space-between;
    padding: 15px;
  }

  &.fixed .header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 20px;
  }

  .header-container {
    @include phone-only {
      flex-direction: column;
      gap: 10px;
    }
  }

  h1 {
    color: $greycolor;
    font-size: 16px;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
    line-height: 2.0;
    display: flex;
    align-items: center;
    gap: 10px;

    @include phone-only {
      font-size: 12px;
    }

    @include desktop-only {
      white-space: nowrap;
    }
  }

  &-filter {
    display: none;
  }

  &.fixed .header-filter {
    @include flex-center;
    color: $whitecolor;
    line-height: 2;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    gap: 15px;
    flex-wrap: nowrap;

    @include phone-only {
      gap: 20px;
      flex-direction: column;
    }
  }
}
