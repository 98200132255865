@use "./variables.scss" as *;

/* Select Styles */

.select-buttons { 
  display: flex; 
  align-items: center; 
  justify-content: center;
  position: relative;
  pointer-events: none;

  &:before {
    font-family: "Font Awesome 6 Free"; 
    content: "\f073";
    font-size: 20px;
    color: $accentcolor;
    margin-bottom: 2px;
    position: absolute;
    left: 12px;
  }

  &:after {
    font-family: "FontAwesome";
    content: "\f078";
    font-size: 20px;
    color: $accentcolor;
    margin-bottom: 2px;
    position: absolute;
    right: 12px;
  }

  .select-default {
    font-family: $primaryfont;
    letter-spacing: 1.5px;
    font-weight: 100;
    cursor: pointer;
    background: $greycolor;
    padding: 14px 45px 14px 45px;
    font-size: 13px;
    text-transform: uppercase;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 0 9px #bbbbbb;
    border: 4px solid $whitecolor;

    &:focus-visible {
      outline: $accentcolor2 solid 2px;
    }
  }
}

/* Button styles */

button {
  &.active {
    border: 4px solid $whitecolor;
    z-index: 999;
    position: relative;
    box-shadow: 0 0 21px $darkgreycolor;
  }

  &.round {
    display: flex;
    align-items: center;
    background: $greycolor;
    border-radius: 50%;
    height: auto;
    padding: 25px;
    margin: 10px;
    font-size: 20px;
  }

  &.grey {
    padding: 12px 20px;
    background: $greycolor;
    display: flex;
    color: $accentcolor;
    letter-spacing: 2px;
    font-size: 11px;
    font-family: $primaryfont;
    border-radius: 2px;
    text-transform: uppercase;
    gap: 5px;
    border: 4px solid $whitecolor
  }

  &.shadow {
    box-shadow: 0 0 9px #bbbbbb;
  }

  &.square {
    aspect-ratio: 1/1;
    padding: 12px 15px;
  }
}

.section button.active, .timeline-buttons button.active {
  border: 4px solid $whitecolor;
}

.section { 
  button:hover  { 
    opacity: 75%; 
  }

  button.deactivate {
    opacity: 40%;
  }
}

button.deactivate, .deactivate button {
  opacity: 45%;
  pointer-events: none;
}

button.active.deactivate {
  box-shadow: 0 0 21px $darkgreycolor; 
}

button.default, .default-btn {
  padding: 10px 20px;
  letter-spacing: 3px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: $primaryfont;
  font-weight: 100;
  color: $greycolor; 
  background: $accentcolor;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    opacity: 75%;
  }
}

.default-btn {
  border: 4px solid $whitecolor;
}

button.round:hover,
select.year-select:hover,
button.grey:hover,
.year-buttons.btn-hover::after,
.year-buttons.btn-hover::before {
  color: $whitecolor;
  background: $accentcolor2;
  opacity: 100%;
}