@use "./variables.scss" as *;

.sky-background {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.cloud {
  position: absolute;
  z-index: -5;

  &.-first {
    animation: moving-cloud 35s linear infinite;
    transform: scale(0.65);
    top: 20%;
  }

  &.-second  {
    animation: moving-cloud 20s linear infinite;
    transform: scale(0.3);
    top: 64%;
  }

  &.-third {
    animation: moving-cloud 30s linear infinite;
    transform: scale(0.5);
    top: 80%;
  }

  &.-fourth {
    animation: moving-cloud 48s linear infinite;
    transform: scale(0.4);
    top: 70%;
  }

  &.-fifth {
    animation: moving-cloud 25s linear infinite;
    transform: scale(0.55);
    top: 15%;
  }
}

.cloud-inner {
	background: $whitecolor;
	border-radius: 100px;
	box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
	height: 120px;
	position: relative;
	width: 350px;

  &:after, &:before {
    background: $whitecolor;
    content: '';
    position: absolute;
    z-indeX: -1;
  }

  &:after {
    border-radius: 100px;
    height: 100px;
    left: 50px;
    top: -50px;
    width: 100px;
  }

  &:before {
    border-radius: 200px;
    width: 180px;
    height: 180px;
    right: 50px;
    top: -90px;
  }
}

@keyframes moving-cloud {
  0% {
      left: -500px;
  }
  100% {
      left: 120%;
  }
}

.aeroplane {
  position: absolute;
  width: 100px;
  height: auto;
  animation: flying-aeroplane 12s linear infinite;
  z-index: 999;
  top: 50%;
}

@keyframes flying-aeroplane {
	0% {
    right: -50%;
  }
  100% {
    right: 150%;
  }
}

