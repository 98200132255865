@use "./variables.scss" as *;

/* Quote */

.quote  {
  padding-bottom: 150px;
  
  blockquote {
    font-size: 40px;
    font-family: $secondaryfont;
    line-height: 2.0;
    padding: 0px 60px;
    margin: 0px;
    letter-spacing: 5px;

    @include phone-only {
      font-size: 30px;
    }
  }

  small {
    font-size: 25px;
    
    @include phone-only {
      font-size: 16px;
    }
  }
}