/* Fonts */

$primaryfont: "Work Sans", sans-serif;
$secondaryfont: "Indie Flower", serif;

/* Colors */
// #8686b8;
// $accentcolor:  #8686b8;
$accentcolor: #7575a8;
$accentcolor2: #b6b6ce;
$beigecolor: #f3f3f2;
$bluecolor: #d6ecf2;
$greycolor: #f3f3f2;
$darkgreycolor: #808080;
$whitecolor: #ffffff;

:root {
  --main-color: $accentcolor;
}

:root .high-contrast {
  --main-color: #001550;
}

/* Box shadow */

$box-shadow: 0 0 21px #b2b2c3;

// Media Queries

$phone-only: 768px;
$desktop-only: 1170px;

@mixin phone-only {
  @media (max-width: $phone-only) { @content; }
}

@mixin desktop-only {
  @media (min-width: $phone-only) { @content; }
}

@mixin phone-up-to-desktop {
  @media (max-width: $desktop-only) { @content; }
}

@mixin tablet-up-to-desktop {
  @media (min-width: $phone-only) and (max-width: $desktop-only) { @content; }
}

/* Mixins */

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin whiteborder {
  box-shadow: $box-shadow;
  border-radius: 4px;
  border: $whitecolor 45px solid;
}
